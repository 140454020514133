.navbar-default {
		border-radius: 0;
		background: $brand-primary;
		border-color: $brand-primary;

		.navbar-header {
				float: left;

				button {
						margin: 12px;
						background-color: $white;
				}
		}

		.nav-pills {
				float: right;

				a {
						padding: 15px;

						&:focus,
						&:hover {
								background-color: transparent;
								outline: none;
								outline-offset: 0;
						}
				}

				img {
						height: 28px;
				}
		}
}

.logo-link {
		display: block;
		text-align: center;
		height: 80px;
		@media (min-width: $screen-lg-min) {
				box-ordinal-group: 2;
				float: left;
				margin-left: 15px;
		}
		@media (min-width: $screen-sm-min) {
				margin-top: 20px;
		}
}

#nav-menu {
		ul {
				list-style-type: none;
				margin: 0;
				padding: 0;

				li {
						a {
								padding: 10px 0;
								display: block;
								font-size: 18px;
								font-weight: bold;
								text-transform: uppercase;
								text-decoration: none;

								&.ativo {
										color: #000;
								}
						}
						border-bottom: 1px solid $brand-primary;
				}
		}
		@media (min-width: $screen-sm-min) {
				float: right;
				font-weight: bold;
				margin-top: 20px;

				ul {
						padding: 25px 0;

						li {
								display: inline-block;
								border-bottom: none;

								a {
										font-size: 16px;
										display: block;
										padding: 5px 20px;
										border-bottom: 3px solid transparent;

										&.ativo,
										&:hover {
												text-decoration: none;
												border-bottom: 3px solid $brand-primary;
										}
								}

								&:nth-child(3n+2) a.ativo,
								&:nth-child(3n+2) a:hover {
										border-color: #FFC009;
								}

								&:nth-child(3n+3) a.ativo,
								&:nth-child(3n+3) a:hover {
										border-color: #FF4C09;
								}

								&:first-child {
										a {
												border-left: none;
										}
								}
						}

						&:hover li a.ativo {
								border-bottom: none;
						}
				}
		}
		@media (min-width: $screen-sm-min) and (max-width: $screen-md-max) {
				box-ordinal-group: 1;
				float: none;

				ul {
						text-align: center;

						li {
								a {
										font-size: 14px;
								}

								&:last-child a {
										padding-right: 20px;
								}
						}
				}
		}
}
