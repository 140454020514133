#filtertags {    
    /* ---------------------------------------------------
        SIDEBAR STYLE
    ----------------------------------------------------- */
    #sidebar {
        width: 250px;
        position: fixed;
        top: 0;
        left: -250px;
        height: 100vh;
        z-index: 999;
        background: #fff;
        color: #000;
        transition: all 0.3s;
        overflow-y: scroll;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
    }

    #sidebar.active {
        left: 0;
    }

    #dismiss {
        width: 35px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #fff;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    #dismiss:hover {
        background: #000000;
        color: #fff;
    }

    .overlay {
        position: fixed;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.7);
        z-index: 998;
        display: none;
    }

    #sidebar .sidebar-header {
        padding: 20px;
        background: #fff;
    }

    #sidebar ul.components {
        padding: 20px 0;
        border-bottom: 1px solid #47748b;
    }

    #sidebar ul p {
        color: #fff;
        padding: 10px;
    }

    #sidebar ul li a {
        padding: 10px;
        font-size: 1.1em;
        display: block;
    }
    #sidebar ul li a:hover {
        color: #7386D5;
        background: #fff;
    }

    #sidebar ul li.active > a, a[aria-expanded="true"] {
        color: #000000;
        background: #fff;
    }


    a[data-toggle="collapse"] {
        position: relative;
    }

    a[aria-expanded="false"]::before, a[aria-expanded="true"]::before {
        content: '\e259';
        display: block;
        position: absolute;
        right: 20px;
        font-family: 'Glyphicons Halflings';
        font-size: 0.6em;
    }
    a[aria-expanded="true"]::before {
        content: '\e260';
    }


    ul ul a {
        font-size: 0.9em !important;
        padding-left: 30px !important;
        background: #6d7fcc;
    }

    ul.CTAs {
        padding: 20px;
    }

    ul.CTAs a {
        text-align: center;
        font-size: 0.9em !important;
        display: block;
        border-radius: 5px;
        margin-bottom: 5px;
    }
    a.download {
        background: #fff;
        color: #7386D5;
    }
    a.article, a.article:hover {
        background: #6d7fcc !important;
        color: #fff !important;
    }


    /* ---------------------------------------------------
        CONTENT STYLE
    ----------------------------------------------------- */
    #content {
        width: 100%;
        padding: 20px;
        min-height: 100vh;
        transition: all 0.3s;
        position: absolute;
        top: 0;
        right: 0;
    }

    /* ---------------------------------------------------
        CHECKBOX STYLE
    ----------------------------------------------------- */

    input[type='checkbox'] {
        /* Hide the checkbox input ui */
        opacity: 0;
    }
    
    /* Apply custom images - size your images according to text and stuff */
    /* Take any custom image you want and encode it with http://websemantics.co.uk/online_tools/image_to_data_uri_convertor/ or alike */
    input[type='checkbox'] + span{
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAABG4AAARuAdCjsmgAAAAHdElNRQfiARcPERAixCL2AAAAWklEQVQoz+2MIRKAIBQF9/9EpxC0ywmd8QKO2cDRuAKFamMwOQZBL+C+uDtPGFmwHDwxZFYI+Ep7eHbF1kiHGnHaPL8pygd/cAXm1YuSxXftRBIGZhyl5UlsJ+SGG75M+iKXAAAAAElFTkSuQmCC) no-repeat center left;
        padding-left: 18px;
    }
    input[type='checkbox']:checked + span{
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAABG4AAARuAdCjsmgAAAAHdElNRQfiARcPEBFM2CMhAAAA3ElEQVQoz32QvWoCQRSFvzskIqbTxsJYpXBfwCYPEEidLpBaK8uAIFgIwU7RkCZFyn2KtIJ90DbabBEtJQTlWOwaVxw908zM/TvfNa5pUmDFsbIseIE3AuE/BAwcBU04IU0oOm/zvTbO92vOenYT3y+8da/UyPMUPz4SQ5l/a23EiJwQhEkCt3xTFYI64ot8khzuRjxS5tMeuGLInDst9/PiDkYf8ccvP1RSmwgTCkkNWlyy5l7TtN8UhToWMdP4EOgAU+/HxI7s2U2aY2HByWiFyCjxTJGNL05EdwvftVY0pV95DgAAAABJRU5ErkJggg==) no-repeat center left;
    }
    
    /* Compatibility: pretty much all browsers except IE prior version 8 */
    
    /* The following styles are for this example only and do not affect the functionality */
    /*span { color: #fb3b3b }*/

hr {
  -moz-border-bottom-colors: none;
  -moz-border-image: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #EEEEEE -moz-use-text-color #FFFFFF;
  border-style: solid none;
  border-width: 1px 0;
  margin: 18px 0;
}
    
}