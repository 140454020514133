#produtos {
  .destaques {
    margin-top: 20px;
    text-align: justify;
    display: flex;
    flex-direction: row;
    flex: 0 1 auto;
    flex-wrap: wrap;

    .col-md-4 {
      text-align: center;
      color: $gray-darker;
      padding-bottom: 56px;
      margin-bottom: 20px;

      &:before {
        content: ' ';
        position: absolute;
        height: 1px;
        bottom: 0;
        left: 15px;
        right: 15px;
        background-color: $brand-primary;
      }

      &:hover {
        text-decoration: none;
      }

      h3 {
        margin-top: 10px;
      }

      .footer {
        position: absolute;
        left: 15px;
        top: auto;
        right: 0;
        bottom: 10px;
      }
    }

    img {
      max-height: 150px;
      width: auto;
      margin: auto;
      display: block;
    }
  }
}
