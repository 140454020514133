#blog {
  a {
    img {
      max-width: 100%;
      height: 20rem;
      width: 50rem;
    }

  }

  .row {

    margin-left: 0 !important;
    padding-bottom: 1rem !important;

    a {
      color: #747474;



    }

  }

  h3 {
    color: $brand-primary;
    text-transform: uppercase;

  }

  .title-article {
    font-size: 18px;
    color: $brand-primary;
  }

  .title-list-article {
    text-transform: initial;
  }

  .newslettertitle {
    color: $brand-primary;
    text-align: justify;
    text-justify: inter-word;

    span {
      font-weight: bold;

    }
  }
  .btn-newsletter{
    background-color:white;
    color:#137cc6;
    border: 1px solid #137cc6;

  }
  .autorartigo{
    span{
      color:$brand-primary;
      text-transform: capitalize;
      font-weight: bold;
    }
  }
  .textoartigo{
    text-align: justify;
    text-justify: inter-word;
  }

}
