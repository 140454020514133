#subcategoria .produtos {
  color: $brand-primary;

  .row {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .col-md-4 {
    text-align: center;
    color: $gray-darker;
    padding-bottom: 56px;
    margin-bottom: 20px;

    &:after {
      content: ' ';
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 0;
      border-bottom: 1px solid $brand-primary;
    }

    &:hover {
      text-decoration: none;
    }

    h3 {
      margin-top: 10px;
    }

    .footer {
      position: absolute;
      left: 15px;
      top: auto;
      right: 15px;
      bottom: 10px;
    }

    img {
      max-height: 200px;
      max-width: 100%;
      margin: auto;
      display: block;
    }
  }

  .btn {
    font-weight: bold;
  }
}
