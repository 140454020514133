#categoria-sem-imagem .subcategorias {
  font-size: 18px;
  color: $brand-primary;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 20px;
  a:hover{
    text-decoration: none;
  }
  & [class*='col-'] {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid $brand-primary;
  }

  .col-xs-1 {
    text-align: center;
    align-items: center;
  }
  @media (min-width: $screen-sm-min) {
    a {
      padding-left:15px;
      padding-right: 15px;
    }

    .col-xs-11 {
      padding-left: 100px;
    }
  }
}
