$brand-primary: #137CC6;
$brand-primary-darker: #0761A1;
$gray-darker: #4A4A4A;
$gray-light: #9B9B9B;
$gray-lighter: #D3D2D4;
$white: #fff;

$header-height: 13vh;

$screen-xs-min: 480px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
