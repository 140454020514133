
#banner-principal {
  div {
    padding: 0;
  }

  img {
    width: 100%;
  }

  .text-box {
    width: 45%;
    text-align: right;
    position: absolute;
    top: 8%;
    right: 8%;
    font-size: 1.3em;
  }

  .carousel-indicators li {
    border: 1px solid $brand-primary;
    background: $brand-primary;
  }

  .carousel-inner a {
    padding-bottom: 43.93%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  .carousel-control {
    background: transparent;
    color: $brand-primary;
    width: 70px;
    @media(min-width: $screen-sm-min) {
      .glyphicon {
        font-size: 70px;
        width: 70px;
        height: 70px;
      }
    }
  }
  @media (min-width: $screen-sm-min) {
    .carousel {
      padding: 0 16px;
    }

    .carousel-caption {
      right: 0;
      padding-bottom: 30px;
      width: 350px;
      left: initial;
      bottom: 0;
      top: 0;

      div {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        background-color: $brand-primary;
        padding: 10px;
        opacity: .6;
      }
    }
  }
}

#novidades {
  > div {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .col-sm-6 {
    @media (min-width: $screen-sm-min) {
      &:last-child {
        border-left: 1px solid $brand-primary;
      }
    }

    .row {
      display: flex;
      align-items: center;
    }
  }

  img {
    width: 100%;
  }

  h3 {
    font-size: 16px;
    font-weight: bold;
  }

  p {
    font-size: 14px;
  }
}

#segmentos {
  ul {
    list-style: none;
    padding: 20px 0;

    li {
      font-weight: bold;
      color: $brand-primary;
      line-height: 40px;
      font-size: 16px;
    }
    @media (min-width: $screen-sm-min) {
      column-count: 2;
      column-rule: 1px solid $brand-primary;

      li {
        padding-left: 10px;
        font-size: 18px;
      }
    }
  }
}
/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
