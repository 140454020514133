//Adaptação para que o carousel funcionde de forma responsiva com ultiplos itens
.multculuna-carousel {
  .carousel-control {
    width: 4%;
    background-image: none;

    &.left {
      margin-left: 15px;
    }

    &.right {
      margin-right: 15px;
    }
  }

  .cloneditem-1,
  .cloneditem-2,
  .cloneditem-3,
  .cloneditem-4,
  .cloneditem-5 {
    display: none;
  }

  .carousel-inner {
    @media all and (min-width: 768px) {
       @media (transform-3d), (-webkit-transform-3d){
        & > .item.active.right,
        & > .item.next {
          transform: translate3d(33.333%, 0, 0);
          left: 0;
        }

        & > .item.active.left,
        & > .item.prev {
          transform: translate3d(-33.333%, 0, 0);
          left: 0;
        }

        & > .item.active,
        & > .item.left,
        & > .item.prev.right {
          transform: translate3d(0, 0, 0);
          left: 0;
        }
      }

      & > .active.left,
      & > .prev {
        left: -33.333%;
      }

      & > .active.right,
      & > .next {
        left: 33.333%;
      }

      & > .active,
      & > .left,
      & > .prev.right {
        left: 0;
      }

      .cloneditem-1,
      .cloneditem-2 {
        display: block;
      }
    }
  }
}
