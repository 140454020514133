#subcategorias {
  margin-top: 20px;

  > .row {
    margin: 0;
    padding: 20px 0;
  }

  .col-xs-6 {
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    flex: 1 0 auto;
    flex-direction: column;
  }

  .col-md-6 {
    color: $white;
    display: block;

    h3 {
      font-size: 18px;
      margin: auto;
      text-align: center;
    }
    @media (min-width: $screen-sm-min) {
      &:before {
        content: ' ';
        position: absolute;
        background: inherit;
        left: 0;
        bottom: 0;
        right: 50%;
        top: 0;
        opacity: .5;
        z-index: 1;
      }
    }

    &:hover {
      &:before {
        opacity: 0;
      }
    }

    &:after {
      content: ' ';
      position: absolute;
      left: 50%;
      margin-left: -10px;
      top: 50%;
      margin-top: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent;
      border-right-color: inherit;
    }
  }

  .col-md-6:nth-child(4n+1),
  .col-md-6:nth-child(4n+2) {
    &:before {
      left: 50%;
      right: 0;
    }

    .col-xs-6 {
      float: right;
    }

    &:after {
      border-width: 10px 0 10px 10px;
      margin-left: 0;
      border-color: transparent;
      border-left-color: inherit;
    }
  }
}
