#contato {
  h4 {
    line-height: 30px;
  }

  .col-md-6 {
    h4 {
      margin-top: 0;
      margin-bottom: 63px;
    }

    .row {
      .col-md-2 {
        text-align: center;
      }

      img {
        height: 42px;
        margin: 6px 0;
      }

      .col-md-10 {
        text-align: center;
        line-height: 56px;
      }
      @media (min-width: $screen-sm-min) {
        .col-md-10 {
          text-align: left;
        }
      }
    }
  }

  form {
    .btn:last-child {
      margin-left: 20px;
    }
  }
}
