/**
* Arquivo para montar o CSS importanto as partes necessárias
**/
@import '_variaveis';
@import '_carousel';
@import '_geral';
@import '_blog';
@import '_header';
@import '_footer';
@import '_index';
@import '_pesquisa';
@import '_produtos';
@import '_categoria';
@import '_categoria-sem-imagens';
@import '_subcategoria';
@import '_subcategoria-sem-imagens';
@import '_produto';
@import '_contato';
@import '_login';
@import '_filter';
@import '_segmento';

