p{
  margin: 0;
  padding: 0;
}
.btn-success {
  background: $brand-primary;

  &:hover {
    background: $brand-primary-darker;
  }
}

main {
  padding-top: 20px;
  min-height: 600px;

  .title-box {
    background-color: #A8CFEA;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    padding-left: 60px;
    height: 1px;
    position: relative;
    margin-bottom: 25px;

    span {
      display: inline-block;
      padding: 0 10px;
      color: $brand-primary;
      background-color: $white;
      position: absolute;
      top: -8px;
    }
  }

  .title-box-blog {
    background-color: #A8CFEA;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
    position: relative;
    margin-bottom: 25px;

    span {
      display: inline-block;
      //padding: 0 10px;
      color: $brand-primary;
      background-color: $white;
      position: absolute;
      top: -8px;
      border-bottom: 3px solid #FFC009;
    }
  }

  .jumbotron.box-descricao {
    border-radius: 0;
    margin: 15px 0;
    text-align: center;
    color: $white;
    background-color: $gray-darker;

    h1 {
      font-size: 28px;
    }
  }

  .pequisa-input {
    padding-top: 20px;
    padding-bottom: 20px;

    .input-group {
      max-width: 600px;
      margin: auto;
    }
  }

  .eqWarap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    flex: 1 0 auto;

     > a,
    > div {
      display: flex;
      flex-direction: column;
    }
  }

  .flex-vertical-middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1 0 auto;
    flex-wrap: wrap;
  }

  .paginacao {
    text-align: center;
  }

  .produtos-quadros {
    text-align: center;
    background: $brand-primary;

    .col-md-12 {
      justify-content: center;
      display: flex;
      flex-wrap: wrap;
    }

    .box-cat {
      display: inline-flex;
      padding: 15px;
      color: $white;
      margin: 10px 0;
      @media (min-width: $screen-sm-min) {
        margin: 0;
      }

      > div {
        padding: 5px;
        width: 100px;
        height: 100%;

        div {
          min-height: 38px;
          display: flex;
          flex-direction: column-reverse;
        }
      }

      &.ativo {
        background-color: $gray-light;
      }

      &:hover {
        text-decoration: none;
      }

      img {
        display: block;
        margin: auto;
        height: 45px;
      }
    }
  }
}
