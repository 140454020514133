footer {
    background-color: $brand-primary;
    color: $white;
    padding-top: 20px;
    ul{
      padding-left: 15px;
      a{
        color: $white;
        font-size: 16px;
        &:hover{
          color: $white;
        }
      }
    }

    span {
        display: block;
    }
    img {
        max-width: 250%;
    }
    @media (min-width: $screen-sm-min) {
    }
    .dados{
      .row{
        padding: 5px 0;
        display : flex;
        align-items : center;
        min-height: 65px;
      }
      .col-xs-3{
        text-align: center;
      }
      img{
        margin: auto;
        width:  30px;
        height: 30px;
      }
    }
    .copyright{
      margin-top: 20px;
      background: $brand-primary-darker;
      padding: 15px 0;
      text-align: center;
    }
}
