#produto-breadcrumb {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 18px;
  .breadcrumb {
    background: none;
  }
}

#produto-descricao {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #000;
  p {
    font-size: 20px;
  }
  h2 {
    font-weight: bold;
  }
  h4 {
    color: $brand-primary
  }
  .saibamais {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    li,
    div {
      font-size: 18px;
      color: #000;
      font-weight: 100;
    }
  }
  .imagem {
    text-align: center;
    padding-top: 20px;
    img {
      max-height: 350px;
      max-width: 100%;
      margin: auto;
    }
  }
  .thumbnails{
    margin-top: 20px;
    text-align: center;
    img{
      height: 80px;
      border: 2px solid #fff;
      padding: 2px;
      margin: 5px;
      &.active{
        border-color: #137CC6;
      }
      &:hover{
        border-color: #137CC6;
      }
    }
  }
  .descricao {
    h2,
    h4,
    {
      padding: 10px 0;
      text-align: justify;
    }
    h2 {
      margin: 0;
      background: $white;
    }
  }
  form {
    padding: 15px;
    border-radius: 3px;
    margin-top: 10%;
  }
}

#produto-especificacoes {
  margin-top: 20px;
  table {
    font-size: 18px;
    td,
    th,
    tr {
      border: none;
    }
  }
}

#produto-doctecnica {
  margin-top: 20px;
  margin-bottom: 20px;
  img {
    height: 80px;
    margin: auto;
    display: block;
  }
  h4 {
    margin: 0 0 15px 0;
  }
  input {
    height: 45px;
  }
}

#produto-relacionados {
  margin-top: 20px;
  margin-bottom: 20px;
  a {
    display: block;
    text-align: center;
  }
  img {
    height: 200px;
    margin: auto;
  }
  .carousel-control {
    opacity: 1;
  }
  .glyphicon {
    color: $brand-primary;
    text-shadow: none;
  }
}
